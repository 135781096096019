/**
 * api接口统一管理
 */
import { post ,get,upload } from './http'
//const path = '/open/openBusine.php'
const path = '/web/'

export function frameBase (param) {
    return get( path+'frameBase',  param)
}

export function listFrameBase (param) {
    return post( path+'listFrameBase',  param)
}

export function listTopicBases (param) {
    return get( path+'listTopicBases', param )
}
export function listTopicBase (param) {
    return get( path+'listTopicBase', param )
}

export function topicBase (param) {
    return get( path+'topicBase', param )
}
export function topicBases (param) {
    return get( path+'topicBases', param )
}
export function zanPage (param) {
    return get( path+'zanPage', param )
}

export function uploadFile(param) {
    //console.log("uploadFile")
    return upload( path+'uploadFile', param )
}

//获取JSON数据
//const getH5StaticJson = data => {
/*
export function getH5StaticJson () {
    //return getJson('static/pagedata.json')
    return get( 'static/pagedata.json', {} )
}
*/

/*

export function getCheckWorkCode (param) {
    return post(
        path ,
        Object.assign({ method: 'getCheckWorkCode' }, param)
    )
}
*/
